#mobile-footer {
    bottom: 0;
    width: 100%;
}

#mobile-menu {
    background: #8FD0CF;
}

#mobile-footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: center;
}

.mobile-link {
    padding-top: 1em;
    padding-bottom: 1em;
    cursor: pointer;
}

.mobile-link span {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.mobile-copy {
    display: flex;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 1em;
}

.mobile-copy img {
    width: 50;
    height: 50;
}

.mobile-copy span {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.mobilelogo {
    text-wrap: wrap;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.mobilelogo span {
    font-size: 16px;
    font-weight: 500;

}

.labeltcs {
    cursor: pointer;
}

.labeltcs:hover {
    text-decoration: underline;
    color: blue;
    transition: color 0.3s;
}