.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #20A2A0;
    padding: 1rem 22rem;
    color: white;
    position: relative;
}

.header-logo img {
    width: 80px;
    height: 80px;
}

.header-nav {
    display: flex;
    align-items: center;
}

.header-nav a {
    margin-left: 2rem;
    color: white;
    text-decoration: none;
    font-size: 1rem;
}

.header-nav a:hover {
    text-decoration: underline;
}

.access-button {
    background-color: white;
    color: #20A2A0;
    font-size: 16px;
    padding: 12px 24px;
    border-radius: 20px;
    margin-left: 2rem;
    border: none;
    cursor: pointer;
}

.access-button:hover {
    background-color: #e0e0e0;
}

.language-dropdown {
    position: relative;
}

.language-button {
    display: flex;
    gap: 0.3rem;
    border: none;
    background-color: transparent;
    color: white;
    margin-left: 1rem;
    font-size: 1rem;
    cursor: pointer;
}

.language-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 0.5rem;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
}

.language-options button {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    background: none;
    border: none;
    padding: 0.5rem;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: background 0.3s;
}

.language-options button:hover {
    background-color: #f0f0f0;
}

.language-options button svg {
    margin-right: 0.5rem;
}


@media (max-width: 768px) {
    .header {
        padding: 1rem 2rem;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        top: 0;
        z-index: 1000;
    }

    .header-logo img {
        width: 60px;
        height: 60px;
    }

    .header-nav {
        display: none;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #20A2A0;
        padding-top: 5rem;
        justify-content: center;
        align-items: center;
        z-index: 999;
    }

    .header-nav.open {
        display: flex;
    }

    .header-nav a,
    .access-button {
        margin-left: 0;
        margin-top: 1rem;
        text-align: center;
    }

    .mobile-menu-icon {
        display: block;
        cursor: pointer;
        font-size: 2rem;
        color: #fff;
        align-self: center;
    }

    .close-menu-icon {
        display: none;
        cursor: pointer;
        font-size: 2rem;
        color: white;
        position: absolute;
        top: 1rem;
        right: 1.5rem;
    }

    .header-nav.open .close-menu-icon {
        display: block;
    }

    .language-dropdown {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding-right: 1rem;
    }

    .language-button {
        background: none;
        border: none;
        color: #fff;
        font-size: 1rem;
        cursor: pointer;
    }

    .language-options {
        position: absolute;
        top: 2rem;
        right: 0;
        background-color: white;
        border: 1px solid #38B2AC;
        z-index: 1000;
        padding: 0.5rem 0;
    }

    .language-options button {
        display: flex;
        padding: 0.5rem 1rem;
        background: none;
        color: #000;
        border: none;
        width: 100%;
        text-align: left;
    }

    .language-options button:hover {
        background-color: #f0f0f0;
    }

    .language-dropdown.open .language-options {
        display: block;
    }
}


@media (min-width: 769px) {
    .mobile-menu-icon {
        display: none;
    }

    .close-menu-icon {
        display: none;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .header {
        padding: 1rem 6rem;
    }
}