.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    text-align: center;
    z-index: 1000;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // gap: 1rem;
}

.cookie-consent p {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.cookie-consent a {
    color: #ffa;
    text-decoration: underline;
}

.cookie-consent button {
    background-color: #20A2A0;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 14px;
}

.cookie-consent button:hover {
    background-color: #1a8281;
}

@media (max-width: 768px) {
    .cookie-consent {
        padding: 0.75rem;
    }

    .cookie-consent p {
        font-size: 0.875rem;
        padding-left: 0.75rem;
        padding-right: 1.75rem;
        text-align: justify;
    }

    .cookie-consent button {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
    }
}

@media (max-width: 480px) {
    .cookie-consent {
        padding: 0.5rem;
    }

    .cookie-consent p {
        font-size: 0.75rem;
    }

    .cookie-consent button {
        padding: 0.5rem 0.75rem;
        font-size: 0.75rem;
    }
}