.feedback-form {
    display: flex;
    justify-content: space-between;
    gap: 5rem;
    padding: 18rem 22rem 4rem 22rem;
    background-color: #20A2A0;
    color: white;
    text-align: left;
    clip-path: polygon(0 30%, 100% calc(0% - 6px), 100% 100%, 0% 100%);
    border-top-right-radius: 50px;

    h2 {
        font-size: 2rem;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
    }

    .feedback-info {
        width: 50%;

        p {
            width: 100%;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 50%;

        .form-group {
            display: flex;
            flex-direction: column;

            label {
                margin-bottom: 5px;
            }

            input,
            textarea {
                padding: 10px;
                border-radius: 16px;
                border: none;
                outline: none;
                width: 100%;
            }

            textarea {
                resize: vertical;
                height: 100px;
            }
        }

        .checkbox-group {
            display: flex;
            align-items: center;

            input {
                margin-right: 10px;
            }

            label {
                font-size: 0.9rem;
                line-height: 1.2;
            }
        }

        button {
            display: block;
            margin-left: auto;
            margin-right: 0;
            font-size: 1rem;
            width: 95px;
            height: 43px;
            padding: 12px 24px;
            background-color: #fff;
            color: #20A2A0;
            border: none;
            border-radius: 24px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #e0e0e0;
            }
        }
    }
}


#mobile-footer {
    bottom: 0;
    width: 100%;
}

#mobile-menu {
    background: #8FD0CF;
}

#mobile-footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow: hidden;
}

.mobile-link {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    cursor: pointer;
}

.mobile-link span {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.mobile-copy {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
}

.mobile-copy span {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.email-sent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.back-button {
    font-size: 16px;
    padding: 10px 15px;
    background-color: #f0f4f8;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.back-button:hover {
    background-color: #868B92;
}

.back-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.message-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.message-container p {
    font-size: 18px;
    color: #333;
    margin: 10px 0;
}

.message-container p:first-child {
    font-size: 20px;
    font-weight: bold;
    color: #4CAF50;
}

@media (max-width: 768px) {
    .feedback-form {
        margin-top: 2rem;
        flex-direction: column;
        padding: 14rem 5% 4rem 5%;
        clip-path: polygon(0 14%, 100% -2.2%, 100% 100%, 0% 100%);
        border-top-right-radius: 50px;

        .feedback-info {
            width: 100%;
        }

        form {
            width: 100%;
        }
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .feedback-form {
        gap: 2rem;
        padding: 10rem 4rem 2rem 4rem;

    }

}