.home-page {
    font-family: 'Inter', sans-serif;
}

.home-page * {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
}