.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: justify;
}

.privacy-policy h2 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
}

.privacy-policy h3 {
    font-size: 1.25rem;
    margin-top: 1rem;
}

.privacy-policy p {
    font-size: 1rem;
    line-height: 1.5;
    margin-top: 0.5rem;
}

.privacy-policy ul {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    list-style-type: disc;
    padding-left: 1.5rem;
}

.privacy-policy a {
    color: #007bff;
    /* Bootstrap blue */
    text-decoration: none;
}

.privacy-policy a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .privacy-policy {
        padding: 15px;
    }

    .privacy-policy h2 {
        font-size: 1.25rem;
    }

    .privacy-policy h3 {
        font-size: 1rem;
    }

    .privacy-policy p {
        font-size: 0.875rem;
    }
}